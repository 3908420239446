import {
  LeftArrowButton,
  RightArrowButton,
} from "@/design-system/components/ArrowButtonsNew";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { convertUTCTimeslotsToAvailabilityByDay } from "@/utils/TimeUtils";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import { useMemo, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import AvailabilityDayWithSlotsNew from "./AvailabilityDayWithSlotsNew";
import styles from "./AvailabilityCarouselNew.module.scss";

interface AvailabilityCarouselNewProps {
  upcomingAvailabilitySlots: string[];
  itemsPerPage: number; // this should be odd, so we have a center slide
}

export default function AvailabilityCarouselNew({
  upcomingAvailabilitySlots,
  itemsPerPage,
}: AvailabilityCarouselNewProps) {
  const [selectedSlide, setSelectedSlide] = useState<number>(1);
  const t = useTranslations("Profile");
  const upcomingAvailability = useMemo(
    () => convertUTCTimeslotsToAvailabilityByDay(upcomingAvailabilitySlots),
    [upcomingAvailabilitySlots],
  );

  if (!upcomingAvailability || upcomingAvailability.length === 0) {
    return null;
  }

  const idealFirstSlide = Math.floor(itemsPerPage / 2);
  const idealLastSlide =
    upcomingAvailability.length - Math.floor(itemsPerPage / 2) - 1;

  return (
    <Flex
      className={styles.availabilityCarouselContainer}
      direction={"column"}
      width={"100%"}
    >
      <TextV2
        textStyle={"Headline M"}
        weightStyle={"medium"}
        m={"auto"}
        mb={"5"}
      >
        {t("availability")}
      </TextV2>
      <Box px={"8"} pb={"2"} minWidth={"0"}>
        <Carousel
          className={styles.carousel}
          centerMode
          centerSlidePercentage={100 / itemsPerPage}
          selectedItem={Math.min(
            Math.max(selectedSlide || idealFirstSlide, idealFirstSlide),
            idealLastSlide,
          )}
          showThumbs={false}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          onChange={(index) => setSelectedSlide(index)}
          showArrows={false}
        >
          {upcomingAvailability.map(([day, slots]) => (
            <AvailabilityDayWithSlotsNew
              key={day}
              isoDate={day}
              slots={slots}
            />
          ))}
        </Carousel>
      </Box>
      <div className={styles.carouselRightArrowButton}>
        <RightArrowButton
          onClick={() =>
            setSelectedSlide(
              Math.min(selectedSlide + itemsPerPage, idealLastSlide),
            )
          }
          disabled={!selectedSlide || selectedSlide >= idealLastSlide}
          isGreenLayout
        />
      </div>
      <div className={styles.carouselLeftArrowButton}>
        <LeftArrowButton
          onClick={() =>
            setSelectedSlide(
              Math.max(selectedSlide - itemsPerPage, idealFirstSlide),
            )
          }
          disabled={!selectedSlide || selectedSlide <= idealFirstSlide}
          isGreenLayout
        />
      </div>
    </Flex>
  );
}
