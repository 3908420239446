"use client";
import { profile as api } from "@/api/entities";
import ProgressiveImage from "@/design-system/components/ProgressiveImage";
import { Box, IconButton, Skeleton } from "@radix-ui/themes";
import { Play } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import styles from "./ProfileVideoNew.module.scss";

const ProfileVideoNew = ({ video }: { video: api.ProfileVideoVariant }) => {
  const [mounted, setMounted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(!!video.stream_url);
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return <Skeleton className={styles.videoSkeleton} />;

  return (
    <Skeleton className={styles.videoSkeleton} loading={isLoading}>
      <Box className={styles.videoContainer} onClick={() => setIsPlaying(true)}>
        <ReactPlayer
          ref={playerRef}
          url={video.stream_url}
          playing={isPlaying}
          onLoad={() => setIsLoading(false)}
          controls={isPlaying}
          width="100%"
          height="100%"
          light={
            <ProgressiveImage
              lowResUrl={video.still_image_blurred_url}
              highResUrl={video.still_image_url_med}
              alt="Video Preview"
            />
          }
          playIcon={
            <IconButton
              className={styles.playIconContainer}
              radius="full"
              color={"gray"}
              variant={"soft"}
              highContrast
            >
              <Play className={styles.playIcon} fill={"currentColor"} />
            </IconButton>
          }
        />
      </Box>
    </Skeleton>
  );
};

export default ProfileVideoNew;
