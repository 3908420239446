import { profile as api } from "@/api/entities";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { useMoment } from "@/hooks/useMoment";
import { Box, Section, Separator } from "@radix-ui/themes";
import { Armchair, CalendarCheck, Check } from "lucide-react";
import { useTranslations } from "next-intl";
import ProfileIconBadges from "../ProfileIconBadges/ProfileIconBadges";
import ProfileRichBodyNew from "../ProfileRichBody/ProfileRichBodyNew";
import CollapsibleText from "@/design-system/components/CollapsibleText/CollapsibleText";

type ProfileQualificationsProps = { profile: api.Profile };

function ProfileQualifications({ profile }: ProfileQualificationsProps) {
  const t = useTranslations("Profile");
  const moment = useMoment();
  const getYearsOfExperience = (dateStr: string): number => {
    // Calculate the number of years between the start date and now
    const startDate = moment(dateStr, "YYYY-MM-DD");
    const currentDate = moment();
    const years = currentDate.diff(startDate, "years"); // Difference in years
    return years;
  };
  const yearsOfExperience = getYearsOfExperience(profile.month_year_started);
  const totalExperienceText =
    profile.month_year_started && yearsOfExperience > 0
      ? yearsOfExperience === 1
        ? t("badges.year_of_experience", {
            yearsOfExperience: getYearsOfExperience(profile.month_year_started),
          })
        : t("badges.years_of_experience", {
            yearsOfExperience: getYearsOfExperience(profile.month_year_started),
          })
      : undefined;
  const sessionsCount = profile.sessions_count;
  const sessionsCountText =
    profile.showSessions && sessionsCount && sessionsCount > 0
      ? sessionsCount < 10
        ? t("sessions_on_IC_less_than_10")
        : t("sessions_on_IC_10_or_more", {
            count: Math.floor(sessionsCount / 10) * 10,
          })
      : undefined;
  const isDateWithinTheLastMonth = (dateStr: string): boolean => {
    const inputDate = moment(dateStr);
    const oneMonthAgo = moment().subtract(1, "months");
    return inputDate.isAfter(oneMonthAgo);
  };
  const joinedAtText =
    profile.showJoined && profile.profile_approved_at
      ? isDateWithinTheLastMonth(profile.profile_approved_at)
        ? t("first_month_at_IC")
        : t("time_at_ic", {
            time: moment
              .duration(
                moment().diff(profile.profile_approved_at, "months"),
                "months",
              )
              .humanize(),
          })
      : undefined;
  const experienceBadges = [
    { icon: <Armchair size={18} />, text: totalExperienceText },
    { icon: <Check size={18} />, text: sessionsCountText },
    { icon: <CalendarCheck size={18} />, text: joinedAtText },
  ].filter((item) => item.text !== undefined);

  return (
    <Box px={{ initial: "5", sm: "0" }}>
      {/* Profile experience section */}
      {profile.experience && (
        <Section size={"1"} p={"0"}>
          <Box>
            <HeadingV2
              as={"h2"}
              textStyle={"Headline M"}
              weightStyle={"medium"}
              my={"5"}
            >
              {t("experience")}
            </HeadingV2>
            <ProfileIconBadges badges={experienceBadges} />
            <CollapsibleText textStyle={"Body M"}>
              <ProfileRichBodyNew
                render_html={profile.is_premium}
                text={profile.experience}
              />
            </CollapsibleText>
          </Box>
        </Section>
      )}

      {/* Profile education section */}
      {profile.education && (
        <Section size={"1"} p={"0"}>
          <Box>
            <HeadingV2
              as={"h2"}
              textStyle={"Headline M"}
              weightStyle={"medium"}
              my={"5"}
            >
              {t("education")}
            </HeadingV2>
            <CollapsibleText textStyle={"Body M"}>
              <ProfileRichBodyNew
                render_html={profile.is_premium}
                text={profile.education}
              />
            </CollapsibleText>
          </Box>
        </Section>
      )}

      {(profile.experience || profile.education) && (
        <Separator
          orientation={"horizontal"}
          style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
          size={"4"}
          mt={{ initial: "6", sm: "8" }}
        />
      )}
    </Box>
  );
}

export default ProfileQualifications;
