import { sanitize } from "isomorphic-dompurify";
import { TextUtils } from "@/utils/TextUtils";
import styles from "./ProfileRichBodyNew.module.scss";

type ProfileRichBodyNewProps = {
  render_html: boolean;
  text: string;
};

function ProfileRichBodyNew({ render_html, text }: ProfileRichBodyNewProps) {
  if (render_html) {
    return (
      <div
        className={styles.container}
        dangerouslySetInnerHTML={{
          __html: sanitize(TextUtils.simpleFormat(text)),
        }}
      />
    );
  }

  return <div className={styles.container}>{text}</div>;
}

export default ProfileRichBodyNew;
