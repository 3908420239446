import { Badge, Box, Card, Flex, Inset } from "@radix-ui/themes";
import ProfileImage from "@components/Profile/Picture/ProfileImage";
import { TextV2 } from "@/design-system/components/text/TextV2";
import React, { useEffect, useState } from "react";
import { useTranslations } from "next-intl";
import { useMoment } from "@/hooks/useMoment";
import { LinkIcon } from "@/design-system/components/LinkIcon/LinkIcon";
import { Calendar } from "lucide-react";
import styles from "./Resource.module.scss";

type ResourceProps =
  | {
      type: "course";
      title: string;
      link: string;
      imageUrl?: string;
      imageAlt: string;
    }
  | {
      type: "event";
      title: string;
      starts_at?: string;
      link: string;
      imageUrl?: string;
      imageAlt: string;
    }
  | {
      type: "publication";
      title: string;
      message?: string;
      link: string;
      imageUrl?: string;
      imageAlt: string;
    };

const Resource: React.FC<ResourceProps> = (props) => {
  const { imageUrl, imageAlt, title, link } = props;
  const t = useTranslations("Common");
  const tResources = useTranslations("Common.resources");
  const moment = useMoment();

  // State to hold the formatted date
  const [formattedDate, setFormattedDate] = useState<string | null>(null);

  const startsAt = props.type == "event" ? props.starts_at : undefined;
  useEffect(() => {
    if (props.type === "event" && startsAt) {
      // Format date only on the client-side
      setFormattedDate(moment(startsAt).format("MMMM D, YYYY h:mm A"));
    }
  }, [startsAt, props.type, moment]);

  const photoType: "course" | "event" | "publication" = props.type;
  const badgeKey = props.type == "event" ? "event" : "course";
  return (
    <Box className={styles.container} width={{ initial: "286px", sm: "360px" }}>
      <Card className={styles.card}>
        <Flex
          direction="column"
          gap={{ initial: "1", sm: "3" }}
          minHeight={{ initial: "262px", sm: "337px" }}
        >
          <Inset clip="border-box" side="top">
            <Box position={"relative"}>
              <ProfileImage
                src={imageUrl}
                alt={imageAlt}
                photoType={photoType}
                aspectRatio={20 / 9}
                style={{
                  borderRadius: "20px 20px 0 0",
                }}
              />
              {["course", "event"].includes(props.type) && (
                <Box position={"absolute"} bottom={"10px"} left={"10px"}>
                  <Badge className={styles.badge} size={"2"} radius={"full"}>
                    {tResources(badgeKey)}
                  </Badge>
                </Box>
              )}
            </Box>
          </Inset>
          <Flex
            direction="column"
            justify={"between"}
            flexGrow={"1"}
            width="100%"
            p={{ initial: "1", sm: "3" }}
          >
            <Flex direction={"column"} gap={{ initial: "1", sm: "2" }}>
              <TextV2 textStyle="Headline XS" weightStyle={"medium"} clamp={3}>
                {title}
              </TextV2>
              {props.type === "event" && formattedDate && (
                <Flex align={"center"} gap={"2"}>
                  <Calendar size={18} />
                  <TextV2 textStyle="Body XL" clamp={1}>
                    {formattedDate}
                  </TextV2>
                </Flex>
              )}
              {props.type === "event" && !startsAt && (
                <Flex align={"center"} gap={"2"}>
                  <Calendar size={18} />
                  <TextV2 textStyle="Body XL" clamp={1}>
                    {t("resources.recurring_event")}
                  </TextV2>
                </Flex>
              )}
              {props.type === "publication" && props.message && (
                <TextV2 textStyle="Body XL" clamp={1}>
                  {props.message}
                </TextV2>
              )}
            </Flex>
            <LinkIcon
              iconType={"chevron"}
              text={
                photoType === "publication" ? t("read_more") : t("learn_more")
              }
              rel={"noopener noreferrer"}
              href={link}
            />
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export { Resource };
