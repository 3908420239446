import { profile as api } from "@/api/entities";
import { Resource } from "@/components/MyProfile/Resources/Resource/Resource";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/design-system/components/Carousel/Carousel";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { Box, Flex, Section, Separator } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import styles from "./ProfileResources.module.scss";
import PodcastPlayerEmbed from "@/design-system/components/PodcastPlayerEmbed/PodcastPlayerEmbed";

export function ProfileResources({ profile }: { profile: api.Profile }) {
  const t = useTranslations("Profile");

  return (
    (profile.courses!.length > 0 ||
      profile.events!.length > 0 ||
      profile.publications!.length > 0 ||
      profile.podcast) && (
      <Box px={{ initial: "5", sm: "0" }}>
        <Separator
          orientation={"horizontal"}
          style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
          size={"4"}
          mt={{ initial: "6", sm: "8" }}
        />

        {/* Profile learning corner section */}
        <Section id="more-info" size={"1"} p={"0"}>
          <Box>
            <HeadingV2
              as={"h2"}
              textStyle={"Headline M"}
              weightStyle={"medium"}
              my={"5"}
            >
              {t("explore_more")}
            </HeadingV2>

            {/* Profile courses & events section */}
            {(profile.courses!.length > 0 || profile.events!.length > 0) && (
              <Box pb={"8"}>
                <HeadingV2
                  as={"h3"}
                  textStyle={"Headline S"}
                  weightStyle={"medium"}
                  mb={"5"}
                >
                  {t("my_offerings")}
                </HeadingV2>
                <Flex direction={"column"} gap={"4"} width={"100%"}>
                  <Carousel>
                    <Flex
                      className={styles.carouselContainer}
                      direction={"column"}
                      width={"100%"}
                    >
                      <CarouselPrevious
                        className={styles.carouselPreviousButton}
                        type={"button"}
                      />
                      <CarouselNext
                        className={styles.carouselNextButton}
                        type={"button"}
                      />
                      <CarouselContent>
                        {profile.events &&
                          profile.events.map((event, index) => (
                            <CarouselItem key={event.id}>
                              <Flex
                                key={event.id}
                                direction={"column"}
                                gap={"4"}
                              >
                                <Resource
                                  type={"event"}
                                  imageUrl={event.image?.blob.url}
                                  imageAlt={t("images_alt.event", { index })}
                                  title={event.title}
                                  starts_at={event.starts_at}
                                  link={event.link}
                                />
                              </Flex>
                            </CarouselItem>
                          ))}
                        {profile.courses &&
                          profile.courses.map((course, index) => (
                            <CarouselItem key={course.id}>
                              <Flex
                                key={course.id}
                                direction={"column"}
                                gap={"4"}
                              >
                                <Resource
                                  type={"course"}
                                  imageUrl={course.image?.blob.url}
                                  imageAlt={t("images_alt.course", { index })}
                                  title={course.title}
                                  link={course.link}
                                />
                              </Flex>
                            </CarouselItem>
                          ))}
                      </CarouselContent>
                    </Flex>
                  </Carousel>
                </Flex>
              </Box>
            )}

            {/* Profile publications section */}
            {profile.publications!.length > 0 && (
              <Box pb={"8"}>
                <HeadingV2
                  as={"h3"}
                  textStyle={"Headline S"}
                  weightStyle={"medium"}
                  mb={"5"}
                >
                  {t("publications")}
                </HeadingV2>
                <Flex direction={"column"} gap={"4"} width={"100%"}>
                  <Carousel>
                    <Flex
                      className={styles.carouselContainer}
                      direction={"column"}
                      width={"100%"}
                    >
                      <CarouselPrevious
                        className={styles.carouselPreviousButton}
                        type={"button"}
                      />
                      <CarouselNext
                        className={styles.carouselNextButton}
                        type={"button"}
                      />
                      <CarouselContent>
                        {profile.publications &&
                          profile.publications.map((publication, index) => (
                            <CarouselItem key={publication.id}>
                              <Flex
                                key={publication.id}
                                direction={"column"}
                                gap={"4"}
                              >
                                <Resource
                                  type={"publication"}
                                  imageUrl={publication.image?.blob.url}
                                  imageAlt={t("images_alt.publication", {
                                    index,
                                  })}
                                  message={publication.description}
                                  title={publication.title}
                                  link={publication.link}
                                />
                              </Flex>
                            </CarouselItem>
                          ))}
                      </CarouselContent>
                    </Flex>
                  </Carousel>
                </Flex>
              </Box>
            )}

            {/* Profile podcasts section */}
            {profile.podcast && (
              <Box pb={"5"}>
                <HeadingV2
                  as={"h3"}
                  textStyle={"Headline S"}
                  weightStyle={"medium"}
                  mb={"5"}
                >
                  {t("podcasts")}
                </HeadingV2>
                <Flex direction={"column"} maxWidth={"746px"}>
                  <PodcastPlayerEmbed src={profile.podcast.link} />
                </Flex>
              </Box>
            )}
          </Box>
        </Section>
      </Box>
    )
  );
}

export default ProfileResources;
