import { profile as api } from "@/api/entities";
import {
  LeftArrowButton,
  RightArrowButton,
} from "@/design-system/components/ArrowButtonsNew";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import * as Dialog from "@radix-ui/react-dialog";
import {
  Box,
  Flex,
  Section,
  Separator,
  VisuallyHidden,
} from "@radix-ui/themes";
import classNames from "classnames";
import { X } from "lucide-react";
import { useTranslations } from "next-intl";
import { useState } from "react";
import ProfileImage from "../Picture/ProfileImage";
import ProfileVideoNew from "../ProfileVideo/ProfileVideoNew";
import styles from "./ProfileMediaGallery.module.scss";
import useScroller from "@/hooks/useScroller";

type ProfileCarouselProps = { profile: api.Profile };

function ProfileMediaGallery({ profile }: ProfileCarouselProps) {
  const [selectedPictureIndex, setSelectedPictureIndex] = useState<
    number | null
  >(null);
  const [openDialog, setOpenDialog] = useState(false);
  const { scrollerRef, canScrollLeft, canScrollRight, scroll } = useScroller();

  const resetStates = (isOpen: boolean) => {
    setOpenDialog(isOpen);
    if (!isOpen) {
      setSelectedPictureIndex(null);
    }
  };

  const handleOnClick = (pictureIndex: number) => {
    setSelectedPictureIndex(pictureIndex);
    setOpenDialog(!openDialog);
  };

  function getPicturesArray(picturesArray: string[]) {
    if (picturesArray.length > 0) {
      // Move the first picture to the end
      return [...picturesArray.slice(1), picturesArray[0]];
    }
    return picturesArray;
  }

  const t = useTranslations("Profile");
  const video = profile.active_videos.find(
    (video) => video.orientation === "landscape",
  );
  const pictures = getPicturesArray(profile.pictures.original);
  const carouselPictures = video ? pictures : pictures.slice(1);
  const numberOfPictures = pictures.length;
  const numberOfMedias = video ? numberOfPictures + 1 : numberOfPictures;

  return (
    numberOfMedias > 1 && (
      <Box px={{ initial: "5", sm: "0" }}>
        <Section size={"1"} p={"0"}>
          <HeadingV2
            as={"h2"}
            textStyle={"Headline M"}
            weightStyle={"medium"}
            my={"5"}
          >
            {t("media_gallery")}
          </HeadingV2>

          <div className={styles.container}>
            {/* If there's no video, highlight the second picture after the profile image */}
            {video ? (
              <ProfileVideoNew video={video} />
            ) : (
              <ProfileImage
                className={styles.mainPicture}
                src={pictures[0]}
                alt={t("images_alt.profile_image", {
                  fullName: profile.full_name,
                  jobTitle: profile.jobtitle,
                })}
                aspectRatio={1}
              />
            )}

            {carouselPictures.length > 1 && (
              <Box className={styles.carouselContainer} mt={"5"}>
                <div className={styles.carouselPreviousButton}>
                  <LeftArrowButton
                    onClick={() => scroll("left")}
                    disabled={!canScrollLeft}
                    desktopOnly
                  />
                </div>
                <div className={styles.carouselNextButton}>
                  <RightArrowButton
                    onClick={() => scroll("right")}
                    disabled={!canScrollRight}
                    desktopOnly
                  />
                </div>

                <Flex
                  ref={scrollerRef}
                  className={styles.carouselPictures}
                  align={"start"}
                  justify={"start"}
                  style={{ overflowX: "scroll", width: "100%" }}
                >
                  <Dialog.Root open={openDialog} onOpenChange={resetStates}>
                    {carouselPictures.map((picture, pictureIndex) => (
                      <Box
                        className={styles.carouselPicture}
                        key={pictureIndex}
                      >
                        <Dialog.Trigger asChild>
                          <Box
                            className={styles.pictureContainer}
                            onClick={() => {
                              handleOnClick(pictureIndex);
                            }}
                          >
                            <ProfileImage
                              className={styles.picture}
                              src={picture}
                              alt={t("images_alt.profile_image", {
                                fullName: profile.full_name,
                                jobTitle: profile.jobtitle,
                              })}
                            />
                          </Box>
                        </Dialog.Trigger>
                      </Box>
                    ))}

                    <Dialog.Portal>
                      <Dialog.Close asChild>
                        <ButtonV2
                          className={styles.dialogCloseButton}
                          radius="full"
                          color={"gray"}
                          variant={"ghost"}
                        >
                          <X size={40} strokeWidth={"1.5"} />
                        </ButtonV2>
                      </Dialog.Close>

                      <Dialog.Overlay className={styles.dialogPictureOverlay} />

                      <Dialog.Content
                        className={styles.dialogPictureContainer}
                        aria-describedby={undefined}
                      >
                        <VisuallyHidden>
                          <Dialog.Title></Dialog.Title>
                        </VisuallyHidden>

                        <DialogPictures
                          pictureId={selectedPictureIndex}
                          carouselPictures={carouselPictures}
                          profile={profile}
                        />

                        {carouselPictures.length > 1 &&
                          selectedPictureIndex! <
                            carouselPictures.length - 1 && (
                            <div className={styles.dialogRightArrowButton}>
                              <RightArrowButton
                                onClick={() =>
                                  setSelectedPictureIndex(
                                    selectedPictureIndex! + 1,
                                  )
                                }
                                isWhiteLayout
                              />
                            </div>
                          )}
                        {carouselPictures.length > 1 &&
                          selectedPictureIndex! > 0 && (
                            <div className={styles.dialogLeftArrowButton}>
                              <LeftArrowButton
                                onClick={() =>
                                  setSelectedPictureIndex(
                                    selectedPictureIndex! - 1,
                                  )
                                }
                                isWhiteLayout
                              />
                            </div>
                          )}
                      </Dialog.Content>
                    </Dialog.Portal>
                  </Dialog.Root>
                </Flex>
              </Box>
            )}
          </div>
        </Section>

        <Separator
          orientation={"horizontal"}
          style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
          size={"4"}
          mt={{ initial: "6", sm: "8" }}
        />
      </Box>
    )
  );
}

function DialogPictures({
  pictureId,
  carouselPictures,
  profile,
}: {
  pictureId: number | null;
  carouselPictures: string[];
  profile: api.Profile;
}) {
  const t = useTranslations("Profile");

  return (
    <Box className={styles.dialogPictures}>
      {carouselPictures.map((picture, pictureIndex) => (
        <ProfileImage
          key={pictureIndex}
          className={classNames(styles.picture, {
            [styles.isHidden]: pictureId !== pictureIndex,
          })}
          src={picture}
          alt={t("images_alt.profile_image", {
            fullName: profile.full_name,
            jobTitle: profile.jobtitle,
          })}
          aspectRatio={1}
        />
      ))}
    </Box>
  );
}

export default ProfileMediaGallery;
