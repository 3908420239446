"use client";
import { useTherapistInquiry } from "@/contexts/TherapistInquiryContext";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { ISODate, TimeSlot } from "@/utils/TimeUtils";
import { Flex } from "@radix-ui/themes";
import moment from "moment";
import { useEffect, useState } from "react";
import styles from "./AvailabilityDayWithSlotsNew.module.scss";
import { useTranslations } from "next-intl";
import classNames from "classnames";

type AvailabilityDayWithSlotsNewProps = {
  isoDate: ISODate;
  slots: TimeSlot[];
};
export default function AvailabilityDayWithSlotsNew({
  isoDate,
  slots,
}: AvailabilityDayWithSlotsNewProps) {
  const { handleAppointmentRequest } = useTherapistInquiry();

  // Initialize state with default (UTC or placeholder) values
  const placeholder = "&nbsp;";
  const [dayOfWeek, setDayOfWeek] = useState(placeholder);
  const [formattedDate, setFormattedDate] = useState(placeholder);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const maxSlots = 2;
  const t = useTranslations("Common");

  useEffect(() => {
    // Update state with client-specific time after component mounts
    const date = moment(isoDate);
    setDayOfWeek(date.format("ddd")); // e.g., "Mon"
    setFormattedDate(date.format("MMM D")); // e.g., "Sep 20"
  }, [isoDate]);

  useEffect(() => {
    if (slots.length > maxSlots) {
      setIsCollapsed(true);
    }
  }, [slots]);

  return (
    <div key={isoDate}>
      <Flex direction={"column"} align={"center"} gap={"1"} mt={"1"} mb={"3"}>
        <span
          className={styles.availabilityWeekday}
          dangerouslySetInnerHTML={{ __html: dayOfWeek }}
        />
        <span
          className={styles.availabilityDate}
          dangerouslySetInnerHTML={{ __html: formattedDate }}
        />
      </Flex>
      <Flex
        className={styles.availabilityTimeWrapper}
        direction={"column"}
        align={"center"}
        gap={"2"}
        wrap={"wrap"}
        mb={"4"}
      >
        {slots.map((slot: TimeSlot, slotIndex: number) => {
          return (
            <ButtonV2
              className={classNames(styles.availabilityItem, {[styles.isHidden]: isCollapsed && slotIndex > maxSlots})}
              key={slot.startUTCFormatted}
              color={"gray"}
              variant={"soft"}
              size={{ initial: "2", md: "3" }}
              radius={"medium"}
              onClick={() =>
                handleAppointmentRequest(
                  slot.startUTCFormatted,
                  "appointment_picker",
                )
              }
            >
              {dayOfWeek != placeholder ? slot.startLocalFormatted : ""}
            </ButtonV2>
          );
        })}
        {isCollapsed && (
          <ButtonV2
            className={styles.availabilityItem}
            color={"gray"}
            variant={"soft"}
            size={{ initial: "2", md: "3" }}
            radius={"medium"}
            onClick={() => setIsCollapsed(false)}
          >
            {t("more")}
          </ButtonV2>
        )}
      </Flex>
    </div>
  );
}
