import React, { useState, useCallback, useEffect } from "react";
import Image from "next/image";
import { Flex, Skeleton } from "@radix-ui/themes";
import { PersonIcon } from "@radix-ui/react-icons";
import { ArmchairIcon, BookIcon, CalendarIcon } from "lucide-react";

const iconMapping = {
  person: PersonIcon,
  gallery: ArmchairIcon,
  course: BookIcon,
  event: CalendarIcon,
  publication: BookIcon,
};

export type ProfileImagePhotoType =
  | "person"
  | "gallery"
  | "course"
  | "event"
  | "publication";

interface ProfileImageProps {
  src?: string;
  alt: string;
  size?: number | string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  photoType?: ProfileImagePhotoType;
  aspectRatio?: number;
  priority?: boolean;
}

const ProfileImage = React.memo(
  ({
    src,
    alt,
    size = "100%",
    className,
    style,
    onClick,
    photoType = "person",
    aspectRatio,
    priority = false
  }: ProfileImageProps) => {
    const [isLoading, setLoading] = useState(!!src);
    const [hasError, setError] = useState(false);

    const IconComponent = iconMapping[photoType];

    const handleLoad = useCallback(() => setLoading(false), []);
    const handleError = useCallback(() => {
      setLoading(false);
      setError(true);
    }, []);

    useEffect(() => {
      setLoading(!!src);
      setError(false);
    }, [src]);

    return (
      <Skeleton
        loading={isLoading}
        style={{ width: size, height: size, borderRadius: "12px" }}
      >
        <div
          style={{
            width: "100%",
            height: aspectRatio ? "auto" : "100%",
            paddingTop: aspectRatio ? `${(1 / aspectRatio) * 100}%` : 0,
            position: "relative",
          }}
        >
          {!src || hasError ? (
            <FallbackIconHolder size={size}>
              <IconComponent
                width={"50%"}
                height={"50%"}
                color="var(--colorV2-grey-medium)"
              />
            </FallbackIconHolder>
          ) : (
            <Image
              src={src}
              width={0}
              height={0}
              sizes="100vw"
              unoptimized
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "12px",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                ...style,
              }}
              alt={alt}
              onLoad={handleLoad}
              onError={handleError}
              className={className}
              onClick={onClick}
              priority={priority}
            />
          )}
        </div>
      </Skeleton>
    );
  },
);

function FallbackIconHolder({
  size,
  children,
}: {
  size: number | string;
  children: React.ReactNode;
}) {
  return (
    <Flex
      style={{
        width: size,
        height: size,
        borderRadius: "12px",
        borderColor: "var(--colorV2-grey-medium)",
        borderWidth: "2px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
      p={"5"}
      align={"center"}
      justify={"center"}
    >
      {children}
    </Flex>
  );
}

ProfileImage.displayName = "ProfileImage";

export default ProfileImage;
