"use client";

import * as analytics from "@/analytics";
import { trackProfileVisit } from "@/api/api.stats";
import { profile as api } from "@/api/entities";
import { TherapistInquiryProvider } from "@/contexts/TherapistInquiryContext";
import { MarketingHeader } from "@components/Header/MarketingHeader";
import { Box, Container, Flex } from "@radix-ui/themes";
import { useSearchParams } from "next/navigation";
import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import ProfileAboutMe from "../ProfileAboutMe/ProfileAboutMe";
import ProfileApproaches from "../ProfileApproaches/ProfileApproaches";
import ProfileBasicInfo from "../ProfileBasicInfo/ProfileBasicInfo";
import ProfileBookingInfoNew from "../ProfileBookingInfo/ProfileBookingInfoNew";
import ProfileMediaGallery from "../ProfileMediaGallery/ProfileMediaGallery";
import { ProfilePersonLinkedData } from "../ProfilePersonLinkedData";
import ProfilePriceDetails from "../ProfilePriceDetails/ProfilePriceDetails";
import ProfileQualifications from "../ProfileQualifications/ProfileQualifications";
import ProfileResources from "../ProfileResources/ProfileResources";
import ProfileSpecialties from "../ProfileSpecialties/ProfileSpecialties";
import { ProfileStickyBookingInfoNew } from "../ProfileStickyBookingInfo/ProfileStickyBookingInfoNew";
import ProfileTabNavigation from "../ProfileTabNavigation/ProfileTabNavigation";
import waveBackgroundImage from "/public/wave.svg";
import styles from "./ProfileContentNew.module.scss";
import { ProfileContactDialogNew } from "../ProfileContact/ProfileContactDialogNew";
import { useMediaQuery } from "react-responsive";
import { QUERIES } from "@/design-system/breakpoints";

type ProfileContentNewProps = {
  profile: api.Profile;
  availabilitySlots: string[];
};

export function ProfileContentNew({
  profile,
  availabilitySlots,
}: ProfileContentNewProps) {
  const searchParams = useSearchParams();
  const lastActivityAt = profile.showActivity
    ? (profile.user.last_activity_at ?? profile.user.updated_at)
    : undefined;
  const landscapeVideo = profile.active_videos.find(
    (video) => video.orientation === "landscape",
  );
  const isMobile = useMediaQuery({ query: QUERIES.mobile });

  const scrollToElement = (
    event: React.MouseEvent<HTMLElement>,
    elementId: string,
  ) => {
    event.preventDefault();
    const element = document.getElementById(elementId);
    if (element) {
      const elementPosition =
        element.getBoundingClientRect().top + window.scrollY;
      const offset = elementId === "more-info" ? 24 : (isMobile ? 64 : 82);
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const [isContactDialogVisible, setContactDialogVisible] =
    React.useState(false);
  const [selectedAppointmentDatetime, setSelectedAppointmentDatetime] =
    React.useState<string | undefined>(undefined);

  useEffect(() => {
    trackProfileVisit(profile.id.toString());
  }, [profile.id]);
  useEffect(() => {
    analytics.directory.profileViewed(
      {
        is_limited_profile: profile.has_limited_profile,
        therapist_user_id: profile.user.id.toString(),
        has_active_video: !!landscapeVideo,
        therapist_display_city_slug: profile.city.slug,
        is_showing_calendar_availability:
          availabilitySlots.length > 0 && profile.calendar_enabled,
        is_available_for_clients: profile.is_available,
      },
      searchParams ?? undefined,
    );
  }, [profile, searchParams, landscapeVideo, availabilitySlots]);

  return (
    <>
      <MarketingHeader />
      <ProfilePersonLinkedData profile={profile} />
      <TherapistInquiryProvider
        setContactDialogVisible={setContactDialogVisible}
        setSelectedAppointmentDatetime={setSelectedAppointmentDatetime}
        profile={profile}
        searchParams={searchParams ?? undefined}
      >
        <Box minHeight={"100vh"}>
          <Box display={{ initial: "none", sm: "block" }}>
            <Box
              className={styles.waveBackgroundImage}
              style={{ backgroundImage: `url(${waveBackgroundImage.src})` }}
            />
          </Box>

          <Container
            className={styles.mainContainer}
            size={"3"}
            width={"100%"}
            maxWidth={"1130px"}
            px={{ initial: "0", sm: "7", md: "9" }}
            m={{ initial: "auto" }}
            mt={{ sm: "-90px" }}
          >
            <Flex
              direction={{ initial: "column", sm: "row" }}
              gap={{ initial: "0", sm: "7", md: "85px" }}
            >
              <Box minWidth={"0"} flexGrow={"1"}>
                <ProfileBasicInfo profile={profile} />
                <ProfileSpecialties profile={profile} />
                <Box display={{ initial: "block", sm: "none" }} my={"7"}>
                  <ProfileBookingInfoNew
                    profile={profile}
                    lastActivityAt={lastActivityAt}
                    showBookingControls={true}
                    upcomingAvailabilitySlots={availabilitySlots}
                    onPriceClick={scrollToElement}
                  />
                </Box>
                <ProfileTabNavigation onClick={scrollToElement} />
                <ProfileAboutMe profile={profile} />
                <ProfileMediaGallery profile={profile} />
                <ProfileApproaches profile={profile} />
                <ProfileQualifications profile={profile} />
                <ProfilePriceDetails profile={profile} />
              </Box>
              <Box
                display={{ initial: "none", sm: "block" }}
                maxWidth={"445px"}
                minWidth={{ initial: "360px", md: "auto" }}
                flexShrink={{ md: "0" }}
              >
                <ProfileBookingInfoNew
                  profile={profile}
                  lastActivityAt={lastActivityAt}
                  showBookingControls={true}
                  upcomingAvailabilitySlots={availabilitySlots}
                  onPriceClick={scrollToElement}
                />
              </Box>
            </Flex>
            <Flex direction={"column"}>
              <ProfileResources profile={profile} />
            </Flex>
          </Container>
          <Footer />
          <ProfileStickyBookingInfoNew
            profile={profile}
            upcomingAvailabilitySlots={availabilitySlots}
          />
        </Box>
      </TherapistInquiryProvider>
      <ProfileContactDialogNew
        profile={profile}
        upcomingAvailabilitySlots={availabilitySlots}
        isOpen={isContactDialogVisible}
        setOpen={setContactDialogVisible}
        selectedDatetime={selectedAppointmentDatetime}
        setSelectedDatetime={setSelectedAppointmentDatetime}
      />
    </>
  );
}

export default ProfileContentNew;
