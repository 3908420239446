import * as analytics from "@/analytics";
import { useUserSession } from "@/contexts/UserSession";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { Flex, Text } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import { useEffect } from "react";

type ProfileContactHeaderProps = {
  user_id: string;
  name: string;
};

export function ProfileContactAuth({
  user_id,
  name,
}: ProfileContactHeaderProps) {
  const t = useTranslations("Profile.contact");
  const { openLoginPopup, openSignupPopup } = useUserSession();
  const returnTo = new URL(window.location.href);
  returnTo.searchParams.set("contact", "1");

  useEffect(() => {
    analytics.auth.trackAuthPromptViewed({
      therapist_user_id: user_id,
      source_page: "therapist_profile_page",
      source_flow: "contact",
    });
  }, [user_id]);

  return (
    <Flex
      direction={"column"}
      flexGrow={"1"}
      flexShrink={"0"}
      flexBasis={"200px"}
      justify={"center"}
      align={"center"}
      p={"5"}
    >
      <Text>{t("auth_subtitle", { name })}</Text>
      <Flex
        direction={"column"}
        align={"start"}
        gap={"3"}
        mt={"6"}
        minWidth={"300px"}
      >
        <ButtonV2
          color={"mint"}
          size={"4"}
          variant={"soft"}
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user comes back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: true,
              auth_kind: "signup",
            });
            openSignupPopup();
          }}
        >
          {t("signup")}
        </ButtonV2>
        <ButtonV2
          color={"mint"}
          size={"4"}
          variant={"solid"}
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            // do not await, the event will be sent when the user comes back from auth0
            analytics.auth.trackAuthStarted({
              has_contact_intent: true,
              auth_kind: "login",
            });
            openLoginPopup();
          }}
        >
          {t("login")}
        </ButtonV2>
      </Flex>
    </Flex>
  );
}
