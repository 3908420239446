import { TextV2 } from "@/design-system/components/text/TextV2";
import { useTranslations } from "next-intl";

type ProfileResponseTimeMessageNewProps = {
  responseTime: number;
};

function ProfileResponseTimeMessageNew(props: ProfileResponseTimeMessageNewProps) {
  const responseTimeKey = getResponseTimeKey(props.responseTime);
  const t = useTranslations("Profile");
  return <TextV2 textStyle={"Body M"}>{t(responseTimeKey)}</TextV2>;
}

export default ProfileResponseTimeMessageNew;

function getResponseTimeKey(responseTime: number) {
  if (responseTime < 3600) {
    return "response_time.within_one_hour";
  } else if (responseTime < 86400) {
    return "response_time.within_one_day";
  } else if (responseTime < 259200) {
    return "response_time.within_three_days";
  } else if (responseTime < 604800) {
    return "response_time.within_one_week";
  } else {
    return "response_time.within_more_than_one_week";
  }
}