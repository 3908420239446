import { IconButton } from "@radix-ui/themes";
import classNames from "classnames";
import { ChevronLeft, ChevronRight } from "lucide-react";
import React from "react";
import styles from "./ArrowButtonsNew.module.scss";

interface ArrowButtonProps {
  onClick: () => void;
  disabled?: boolean;
  isGreenLayout?: boolean;
  isWhiteLayout?: boolean;
  desktopOnly?: boolean;
}

export const RightArrowButton: React.FC<ArrowButtonProps> = ({
  onClick,
  disabled,
  isGreenLayout = false,
  isWhiteLayout = false,
  desktopOnly = false,
}) => {
  return (
    <IconButton
      size={"2"}
      radius={"full"}
      className={classNames(styles.arrowButton, {
        [styles.isGreenLayout]: isGreenLayout,
        [styles.isWhiteLayout]: isWhiteLayout,
        [styles.isHidden]: disabled,
        [styles.desktopOnly]: desktopOnly,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <ChevronRight className={styles.arrowIcon} size={20} />
    </IconButton>
  );
};

export const LeftArrowButton: React.FC<ArrowButtonProps> = ({
  onClick,
  disabled,
  isGreenLayout = false,
  isWhiteLayout = false,
  desktopOnly = false,
}) => {
  return (
    <IconButton
      size={"2"}
      radius={"full"}
      className={classNames(styles.arrowButton, {
        [styles.isGreenLayout]: isGreenLayout,
        [styles.isWhiteLayout]: isWhiteLayout,
        [styles.isHidden]: disabled,
        [styles.desktopOnly]: desktopOnly,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <ChevronLeft className={styles.arrowIcon} size={20} />
    </IconButton>
  );
};
