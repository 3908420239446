import { profile as api } from "@/api/entities";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { useSessionOfferLabels } from "@/hooks/useSessionOfferLabels";
import { Box, Flex } from "@radix-ui/themes";
import { useTranslations, useFormatter } from "next-intl";

export function ProfilePriceOffers({ profile }: { profile: api.Profile }) {
  const t = useTranslations("Profile");
  const format = useFormatter();
  const { labelForType } = useSessionOfferLabels();

  const getFormattedSessionPrice = (offer: api.PrimarySessionOffer | api.SecondarySessionOffer) => {
    return format.number(
      offer.price,
      {
        style: "currency",
        currency:
          offer.currency ||
          profile.price_currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      },
    );
  };

  return (
    <>
      {profile.primary_session_offer && (
        <Box pb={"5"}>
          <HeadingV2
            as={"h3"}
            textStyle={"Headline S"}
            weightStyle={"medium"}
            mb={"1"}
          >
            {profile.primary_session_offer.session_type === "custom"
              ? labelForType(profile.primary_session_offer.session_type)
              : t("session_type", {
                  sessionType: labelForType(
                    profile.primary_session_offer.session_type,
                  ),
                })}
          </HeadingV2>
          <Flex direction={"column"}>
            <TextV2 textStyle={"Body XL"}>
              {t("session_price_length", {
                sessionPrice: getFormattedSessionPrice(profile.primary_session_offer),
                sessionLength: profile.primary_session_offer.duration,
              })}
            </TextV2>
          </Flex>
        </Box>
      )}

      {profile.secondary_session_offers.length > 0 &&
        profile.secondary_session_offers.map(
          (offer, i) =>
            offer.session_type !== "initial" && (
              <Box key={i} pb={"5"}>
                <HeadingV2
                  as={"h3"}
                  textStyle={"Headline S"}
                  weightStyle={"medium"}
                  mb={"1"}
                >
                  {offer.session_type === "custom"
                    ? offer.title || labelForType(offer.session_type)
                    : t("session_type", {
                        sessionType:
                          offer.title || labelForType(offer.session_type),
                      })}
                </HeadingV2>
                <Flex direction={"column"}>
                  <TextV2 textStyle={"Body XL"}>
                    {offer.duration ? (
                      t("session_price_length", {
                        sessionPrice: getFormattedSessionPrice(offer),
                        sessionLength: offer.duration,
                      })
                    ) : (
                      getFormattedSessionPrice(offer)
                    )}
                  </TextV2>
                </Flex>
              </Box>
            ),
        )}
    </>
  );
}

export default ProfilePriceOffers;
