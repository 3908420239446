import { profile as api } from "@/api/entities";
import {
  LeftArrowButton,
  RightArrowButton,
} from "@/design-system/components/ArrowButtonsNew";
import { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ProfileCarousel from "../ProfileCarousel";
import styles from "./ProfileCarouselWithArrowsNew.module.scss";

type ProfileCarouselProps = { profile: api.Profile };

function ProfileCarouselWithArrowsNew({ profile }: ProfileCarouselProps) {
  const [selectedSlide, setSelectedSlide] = useState<number>(0);
  const numberOfPictures = profile.pictures.original.length;

  return (
    <div className={styles.container}>
      <div className={styles.carouselContainer}>
        <ProfileCarousel
          profile={profile}
          selectedItem={selectedSlide}
          onChange={(index) => setSelectedSlide(index)}
          profileImageClassName={styles.carouselImage}
        />
      </div>
      {numberOfPictures > 1 && selectedSlide < numberOfPictures - 1 && (
        <div className={styles.carouselRightArrowButton}>
          <RightArrowButton
            onClick={() => setSelectedSlide(selectedSlide + 1)}
            desktopOnly
          />
        </div>
      )}
      {numberOfPictures > 1 && selectedSlide > 0 && (
        <div className={styles.carouselLeftArrowButton}>
          <LeftArrowButton
            onClick={() => setSelectedSlide(selectedSlide - 1)}
            desktopOnly
          />
        </div>
      )}
    </div>
  );
}

export default ProfileCarouselWithArrowsNew;
