"use client";

import * as analytics from "@/analytics";

import { trackProfileVisit } from "@/api/api.stats";
import { profile as api } from "@/api/entities";
import { blog as blogApi } from "@/api";
import { QUERIES } from "@/design-system/breakpoints";
import { LayoutContainer } from "@/design-system/components/LayoutContainer";
import { MobileView } from "@/design-system/responsive-helpers/MobileView";
import { NonMobileView } from "@/design-system/responsive-helpers/NonMobileView";
import addressFormatter from "@fragaria/address-formatter";
import { useSearchParams } from "next/navigation";
import React, { useEffect } from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";
import AvailabilityScrollArea from "./Availability/AvailabilityScrollArea";
import ProfileAboutAndCategories from "./ProfileAboutAndCategories";
import ProfileBookingInfo from "./ProfileBookingInfo";
import ProfileCarousel from "./ProfileCarousel";
import ProfileCarouselWithArrows from "./ProfileCarouselWithArrows";
import ProfileHeader from "./ProfileHeader";
import ProfileMyApproach from "./ProfileMyApproach";
import { ProfilePersonLinkedData } from "./ProfilePersonLinkedData";
import ProfileQualifications from "./ProfileQualifications";
import ProfileRichBody from "./ProfileRichBody";
import { ProfileVideo } from "./ProfileVideo";
import ProfileWhereIPractice from "./ProfileWhereIPractice";
import { TherapistInquiryProvider } from "@/contexts/TherapistInquiryContext";
import { MarketingHeader } from "@components/Header/MarketingHeader";
import { ProfileStickyBookingInfo } from "@components/Profile/ProfileStickyBookingInfo";
import { ProfileContactDialog } from "@components/Profile/ProfileContact/ProfileContactDialog";
import ProfileBlogPosts from "@components/Profile/ProfileBlogPosts";

type ProfileContentProps = {
  profile: api.Profile;
  availabilitySlots: string[];
  blogPosts: blogApi.BlogPost[];
};

export function ProfileContent({
  profile,
  availabilitySlots,
  blogPosts,
}: ProfileContentProps) {
  const formattedAddress = addressFormatter.format(
    {
      houseNumber: profile.number,
      road: profile.street,
      city: profile.city.name,
      postcode: profile.zip,
    },
    {
      output: "array",
    },
  );
  const searchParams = useSearchParams();
  const lastActivityAt = profile.showActivity
    ? (profile.user.last_activity_at ?? profile.user.updated_at)
    : undefined;
  const landscapeVideo = profile.active_videos.find(
    (video) => video.orientation === "landscape",
  );

  const [isContactDialogVisible, setContactDialogVisible] =
    React.useState(false);
  const [selectedAppointmentDatetime, setSelectedAppointmentDatetime] =
    React.useState<string | undefined>(undefined);

  useEffect(() => {
    trackProfileVisit(profile.id.toString());
  }, [profile.id]);
  useEffect(() => {
    analytics.directory.profileViewed(
      {
        is_limited_profile: profile.has_limited_profile,
        therapist_user_id: profile.user.id.toString(),
        has_active_video: !!landscapeVideo,
        therapist_display_city_slug: profile.city.slug,
        is_showing_calendar_availability:
          availabilitySlots.length > 0 && profile.calendar_enabled,
        is_available_for_clients: profile.is_available,
      },
      searchParams ?? undefined,
    );
  }, [profile, searchParams, landscapeVideo, availabilitySlots]);

  return (
    <>
      <MarketingHeader />
      <ProfilePersonLinkedData profile={profile} />
      <TherapistInquiryProvider
        setContactDialogVisible={setContactDialogVisible}
        setSelectedAppointmentDatetime={setSelectedAppointmentDatetime}
        profile={profile}
        searchParams={searchParams ?? undefined}
      >
        <PageWrapperWithStickyFooter>
          <LayoutContainer>
            <ProfileWrapper>
              <ProfileMainContentWrapper>
                <ProfileHeader profile={profile} />
                {landscapeVideo && (
                  <NonMobileView>
                    <ProfileVideo
                      video={landscapeVideo}
                      therapistUserId={profile.user.id.toString()}
                    />
                  </NonMobileView>
                )}
                <ProfileRichBody
                  render_html={profile.is_premium}
                  text={profile.professional_statement ?? ""}
                />
                <MobileView>
                  <ProfileBookingInfo
                    profile={profile}
                    lastActivityAt={lastActivityAt}
                    showBookingControls={false}
                    upcomingAvailabilitySlots={availabilitySlots}
                  />
                </MobileView>

                <MobileView>
                  {profile.calendar_enabled && availabilitySlots.length > 0 && (
                    <AvailabilityScrollArea
                      upcomingAvailabilitySlots={availabilitySlots}
                    />
                  )}
                </MobileView>

                <ProfileAboutAndCategories
                  about={profile.about ?? ""}
                  is_premium={profile.is_premium}
                  categories={profile.categories}
                />
                <MobileView>
                  {landscapeVideo && <ProfileCarousel profile={profile} />}
                </MobileView>
                {!profile.has_limited_profile && profile.approach && (
                  <ProfileMyApproach
                    is_premium={profile.is_premium}
                    myApproach={profile.approach}
                  />
                )}

                {!profile.has_limited_profile && (
                  <ProfileWhereIPractice
                    latitude={profile.lat}
                    longitude={profile.lng}
                    addressLines={formattedAddress}
                  />
                )}
                {!profile.has_limited_profile && (
                  <ProfileQualifications
                    experience={profile.experience}
                    education={profile.education}
                    languages={profile.languages.map(
                      (language) => language.name,
                    )}
                    insuranceSlugs={profile.insurances.map(
                      (insurance) => insurance.slug,
                    )}
                  />
                )}
                {!profile.has_limited_profile && blogPosts.length > 0 && (
                  <ProfileBlogPosts blogPosts={blogPosts} />
                )}
              </ProfileMainContentWrapper>
              <ProfileBookingsWrapper>
                <ProfileCarouselWithArrows profile={profile} />
                <WebProfileBookingInfoSpacer>
                  <ProfileBookingInfo
                    profile={profile}
                    lastActivityAt={lastActivityAt}
                    showBookingControls={true}
                    upcomingAvailabilitySlots={availabilitySlots}
                  />
                </WebProfileBookingInfoSpacer>
              </ProfileBookingsWrapper>
            </ProfileWrapper>
          </LayoutContainer>
          <Footer />
          <ProfileStickyBookingInfo profile={profile} />
        </PageWrapperWithStickyFooter>
      </TherapistInquiryProvider>
      <ProfileContactDialog
        profile={profile}
        upcomingAvailabilitySlots={availabilitySlots}
        isOpen={isContactDialogVisible}
        setOpen={setContactDialogVisible}
        selectedDatetime={selectedAppointmentDatetime}
        setSelectedDatetime={setSelectedAppointmentDatetime}
      />
    </>
  );
}

export default ProfileContent;

const PageWrapperWithStickyFooter = styled.div`
  min-height: 100vh;
`;

const ProfileWrapper = styled.section`
  display: flex;
`;

const ProfileMainContentWrapper = styled.section`
  padding-bottom: var(--spacer-size-7);
  padding-right: var(--spacer-size-6);
  display: flex;
  flex: 1 0 400px;
  flex-direction: column;
  gap: 44px;
  min-width: 0; // https://github.com/leandrowd/react-responsive-carousel/issues/512#issuecomment-767147310

  @media ${QUERIES.mobile} {
    padding-right: 0;
    flex: 1;
  }
`;

const ProfileBookingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  // these make the booking info stick in place, which I am not sure is the best UX
  //   position: sticky;
  //   top: 120px;
  margin-top: 60px;
  flex: 0 1 398px;
  height: fit-content;
  margin-left: var(--spacer-size-2);
  min-width: 250px; // https://github.com/leandrowd/react-responsive-carousel/issues/512#issuecomment-767147310

  @media ${QUERIES.mobile} {
    display: none;
  }
`;

const WebProfileBookingInfoSpacer = styled.div`
  width: 100%;
  padding: 0 var(--spacer-size-4);
`;
