import { profile as api } from "@/api/entities";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { TextV2 } from "@/design-system/components/text/TextV2";
import { Box, Card, Flex, Section, Separator } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import ProfileRichBodyNew from "../ProfileRichBody/ProfileRichBodyNew";
import CollapsibleText from "@/design-system/components/CollapsibleText/CollapsibleText";

type ProfileApproachesProps = { profile: api.Profile };

function ProfileApproaches({ profile }: ProfileApproachesProps) {
  const t = useTranslations("Profile");

  return (
    profile.approach && (
      <Box px={{ initial: "5", sm: "0" }}>
        <Section id="approach" size={"1"} p={"0"}>
          <Box>
            <HeadingV2
              as={"h2"}
              textStyle={"Headline M"}
              weightStyle={"medium"}
              my={"5"}
            >
              {t("approach")}
            </HeadingV2>

            {profile.approaches && profile.approaches.length > 0 && (
              <Card size={{ initial: "2", md: "3" }} mb={"6"}>
                <Flex direction={"column"}>
                  <TextV2
                    textStyle={"Headline S"}
                    weightStyle={"medium"}
                    mb={"4"}
                  >
                    {t("treatment_approaches")}
                  </TextV2>
                  <Flex direction={"column"} gap={{ initial: "3", md: "4" }}>
                    {profile.approaches.map((approach, approachIndex) => (
                      <Flex direction={"column"} gap={"1"} key={approachIndex}>
                        <TextV2
                          textStyle={"Headline XS"}
                          weightStyle={"medium"}
                        >
                          {approach.name}
                        </TextV2>
                        {approach.excerpt && (
                          <TextV2
                            textStyle={"Body XL"}
                            weightStyle={"regular"}
                            color={"gray"}
                          >
                            {approach.excerpt}
                          </TextV2>
                        )}
                      </Flex>
                    ))}
                  </Flex>
                </Flex>
              </Card>
            )}

            <CollapsibleText textStyle={"Body M"}>
              <ProfileRichBodyNew
                render_html={profile.is_premium}
                text={profile.approach}
              />
            </CollapsibleText>
          </Box>
        </Section>

        <Separator
          orientation={"horizontal"}
          style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
          size={"4"}
          mt={{ initial: "6", sm: "8" }}
        />
      </Box>
    )
  );
}

export default ProfileApproaches;
